import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Container, Row, Col } from "react-bootstrap"

const Header = ({ siteTitle, description }) => (
  <header
    style={{
      background: `#2196F3`,
    }}
  >
    <Container>
      <Row>
        <Col xs={12} md={6} style={{ padding: "1rem 0" }}>
          <h1 style={{ margin: 0 }}>
            <Link
              to="/"
              style={{
                color: `white`,
                textDecoration: `none`,
              }}
            >
              {siteTitle}
            </Link>
          </h1>
          <h5 style={{ color: "#ddd" }}>{description}</h5>
        </Col>
        <Col xs={12} md={6} style={{ padding: "1rem 0", display: "flex" }}>
          <div
            className="nav"
            style={{
              display: "flex",
              color: "white",
              marginLeft: "auto",
              marginTop: "auto",
            }}
          >
            <Link to="/">Home</Link>
            <Link to="/about-us">About Us</Link>
            <Link to="/networking">Network Solutions</Link>
            <Link to="/email">Email Solutions</Link>
            <Link to="/contact">Contact Us</Link>
          </div>
        </Col>
      </Row>
    </Container>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
